<template>
  <div class="autocomplete" v-click-outside="outsideClick">
    <input
      type="text"
      v-model="search"
      @input="debounceOnChange"
      @keydown.down.prevent="onArrowDown"
      :isFromLanding="isFromLanding"
      class="formControl loction-icon"
      :readonly="isReadonly"
      placeholder="Location "
      style="padding: 0px 10px !important;"
      
    />
    <!-- <label class="fieldFloating__label">Location</label> -->
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        @mouseenter="highlightOption(i)"
        @mouseleave="clearHighlight()"
        :class="{ 'highlighted': i === highlightedIndex }"
        class="autocomplete-result"
      >
        {{ result }}
      </li>
    </ul>
  </div>
</template>
<script>
import { debounce } from "@/utils/debounce";
import { getLocationData } from "@/api/properties";

export default {
  name: "autocomplete",
  props: ["locationData", "isFromLanding", "isReadonly", "fromFront"],
  watch: {
    locationData(value) {
      this.search = value;
    },
  },
  data() {
    return {
      search: "",
      results: [],
      isOpen: false,
      items: [],
      highlightedIndex: -1,
    };
  },
  created() {
    if (this.locationData) {
      this.search = this.locationData;
    }
  },
  methods: {
    debounceOnChange: debounce(function () {
      this.handleLocationChange();
    }, 800),
    handleLocationChange() {
      this.$emit("getLocation", this.search, true);
      if (!this.isFromLanding) {
        this.$emit("getLocation", this.search);
      }

      if (this.search == "") {
        this.isOpen = false;
      } else {
        getLocationData(
          this.search,
          "&sessiontoken=1234567890&types=(cities)&country=IN",
          this.fromFront
        ).then((response) => {
          setTimeout(() => {}, 10);
          this.results = response.data["predictions"].map(function (item) {
            return item["description"];
          });
        });
        this.isOpen = true;
      }
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
      this.$emit("getLocation", this.search, true);
    },
    outsideClick() {
      this.isOpen = false;
    },
    onArrowDown() {
      if (this.highlightedIndex < this.results.length - 1) {
        this.highlightedIndex++;
      } else {
        this.highlightedIndex = 0; // Reset to the first option if reached the end
      }
    },
    highlightOption(index) {
      this.highlightedIndex = index;
    },
    clearHighlight() {
      this.highlightedIndex = -1;
    },
  },
};
</script>

<style scoped lang="scss">

.fieldFloating .autocomplete-result.highlighted {
  font-family: "Inter-SemiBold";
  background-color: rgba(38, 140, 171, 0.08);
  font-weight: bold;
  color: #28485e;
}
.loction-icon{
  position: relative;
  
}
// .loction-icon::after{
//   content: 's';
//   position: absolute;
//   width: 10px;
//   height: 10px;
//   background-color: red;
// }
</style>
